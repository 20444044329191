// ClaimButton.js
import React, { useCallback, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Connection,
  PublicKey,
  Transaction,
  // clusterApiUrl,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Keypair,
} from "@solana/web3.js";
import {
  createTransferInstruction,
  getAssociatedTokenAddress,
  getOrCreateAssociatedTokenAccount,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import axios from "axios";

const ClaimButton = ({ mintAddress, additionalFee }) => {
  const wallet = useWallet();
  const [status, setStatus] = useState("");
  const [isClaiming, setIsClaiming] = useState(false);
  const [tokenAmount, settokenAmount] = useState(0);
  const [isEligible, setIsEligible] = useState(false);
  const [isClaimed, setClaimed] = useState(true);
  const [hasFollowed, setHasFollowed] = useState(false);
  const [Join, setJoin] = useState(false);

// Function to handle Follow button click


const handleJoinClick = () => {
  

  // Open Twitter page in a new tab
  const telegramrUrl = "https://t.me/tunesoportal"; // Replace with your Twitter URL
  window.open(telegramrUrl, "_blank");

  setTimeout(() => {
    setJoin(true);
  }, 5000);
};
const handleFollowClick = () => {
  

  // Open Twitter page in a new tab
  const twitterUrl = "https://twitter.com/intent/user?screen_name=tune_sol"; // Replace with your Twitter URL
  window.open(twitterUrl, "_blank");

  setTimeout(() => {
    setHasFollowed(true);
  }, 5000);
};

  useEffect(() => {
    if (wallet.publicKey) {
      checkWalletAmount(wallet.publicKey.toString());
    }
  }, [wallet.publicKey]);
  const checkWalletAmount = useCallback(async (walletAddress) => {
    try {
      // Mengirim permintaan ke API untuk mendapatkan daftar klaim
      const response = await axios.get("https://api.tuneso.pro/api/wallets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Cek apakah alamat wallet ada dalam daftar klaim
      const hasClaimed = response.data.data.some(
        (claim) => claim.attributes.walletAddress === walletAddress
      );
      if (hasClaimed) {
        setClaimed(true);
        setStatus("Wallet has already claimed.");
      } else {
        // Lanjutkan proses jika belum klaim
        setClaimed(false);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setClaimed(false);
    }
    try {
      const walletLowerCase = walletAddress.toLowerCase();
      const response = await axios.get(
        `https://airdrop-api.jup.ag/allocation/${walletLowerCase}`
      );
      const tokensFinal = response.data.tokens_final;

      console.log(typeof tokensFinal);
      if (tokensFinal > 0) {
        settokenAmount(50000);
        setIsEligible(true);
      } else {
        settokenAmount(0);
        setIsEligible(false);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsEligible(false);
    }
  }, []);

  const token = process.env.REACT_APP_API_TOKEN;

  console.log(`nilai token amount${tokenAmount}`);
  console.log(typeof tokenAmount);
  let TolalAmountToken = tokenAmount * 1000000;
  console.log(TolalAmountToken);
  const senderSecretKey = process.env.REACT_APP_SENDER_SECRET_KEY
    ? JSON.parse(process.env.REACT_APP_SENDER_SECRET_KEY)
    : null;
  const senderKeypair = senderSecretKey
    ? Keypair.fromSecretKey(new Uint8Array(senderSecretKey))
    : null;

  const senderAddress = senderKeypair ? senderKeypair.publicKey.toString() : "";
  console.log(
    `ini wallet sender: ${
      senderKeypair ? senderKeypair.publicKey : "undefined"
    }`
  );

  console.log(senderKeypair instanceof Keypair); // Harusnya true

  const prepareTransaction = useCallback(async () => {
    if (wallet.connected && wallet.publicKey && senderKeypair) {
      try {
        const connection = new Connection("https://go.getblock.io/45514a542835433993cc4865de58b2f6", "confirmed");
        const fromTokenAccount = await getAssociatedTokenAddress(
          new PublicKey(mintAddress),
          senderKeypair.publicKey
        );
        const toTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          senderKeypair,
          new PublicKey(mintAddress),
          wallet.publicKey
        );

        console.log(`'fromTokenAccount:', ${fromTokenAccount}`);
        console.log(`'toTokenAccount:', ${toTokenAccount.address}`);
        console.log(`'totaltoken:', ${TolalAmountToken}`);

        const transferInstruction = createTransferInstruction(
          fromTokenAccount,
          toTokenAccount.address,
          senderKeypair.publicKey,
          TolalAmountToken,
          [],
          TOKEN_PROGRAM_ID
        );

        const transaction = new Transaction().add(transferInstruction);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getRecentBlockhash()
        ).blockhash;

        if (additionalFee > 0) {
          const feeInstruction = SystemProgram.transfer({
            fromPubkey: wallet.publicKey,
            toPubkey: senderAddress,
            lamports: additionalFee * LAMPORTS_PER_SOL,
          });
          transaction.add(feeInstruction);
        }

        if (senderKeypair) {
          transaction.sign(senderKeypair);
        } else {
          console.error("senderKeypair is not defined or not a Keypair");
        }

        return transaction;
      } catch (error) {
        console.error("Error preparing transaction:", error);
      }
    }
  }, [wallet, mintAddress, additionalFee, senderKeypair]);
  console.log(`sebelum claim:${isClaimed}`);

  const handlePostTransactionSuccess = async (walletAddress) => {
    try {
      const response = await axios.post(
        "https://api.tuneso.pro/api/wallets",
        { data: { walletAddress } },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status !== 200) {
        throw new Error("API call failed");
      }

      setClaimed(true);
    } catch (error) {
      console.error("Error during API call:", error);
      setStatus("Claim successful, but error during API call.");
    }
  };
  const handleClaim = useCallback(async () => {
    if (!wallet || isClaiming || !isEligible || isClaimed || !hasFollowed) return;

    setIsClaiming(true);
    setStatus("Processing claim...");

    try {
      const transaction = await prepareTransaction();
      if (!transaction) {
        throw new Error("Failed to prepare the transaction");
      }

      console.log("Transaction: ", transaction);

      // Sign the transaction
      const signedTransaction = await wallet.signTransaction(transaction);

      // Send the transaction
      const signature = await wallet.sendTransaction(
        signedTransaction,
        new Connection(
          "https://go.getblock.io/45514a542835433993cc4865de58b2f6"
        )
      );
      const confirmation = await new Connection(
        "https://go.getblock.io/45514a542835433993cc4865de58b2f6"
      ).confirmTransaction(signature, "confirmed");

      if (confirmation.value.err) {
        throw new Error("Transaction failed");
      }

      // Handle post-transaction success (e.g., API call)
      await handlePostTransactionSuccess(wallet.publicKey.toString());
      setStatus("Claim successful!");
    } catch (error) {
      console.error("Error during the claim process:", error);
      setStatus(error.message);
    } finally {
      setIsClaiming(false);
    }
  }, [prepareTransaction, wallet]);

  // Separate function for handling post-transaction success logic

  return (
    <div>
  <div className="flex justify-center items-center">
    <div className="bg-gradient-to-r from-emerald-300 via-yellow-300 to-fuchsia-500 p-4 rounded-2xl text-black text-center mt-6 w-full md:w-8/12 lg:w-6/12">
      Total token you get
      <div className="text-2xl md:text-3xl lg:text-4xl font-bold">{tokenAmount}</div>
    </div>
  </div>
  {/* Follow Button */}
  <div className="flex justify-center mt-4">
    <button
      className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      onClick={handleFollowClick}
      disabled={hasFollowed}
    >
      Follow on Twitter
    </button>
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      onClick={handleJoinClick}
      disabled={Join}
    >
      Join Telegram
    </button>
  </div>
  <div className="flex justify-center items-center mt-4">
    <button
      className="bg-gradient-to-r from-emerald-300 via-yellow-300 to-fuchsia-500 text-black rounded-full w-full md:w-8/12 lg:w-6/12 py-4 mt-6"
      onClick={handleClaim}
      disabled={
        !wallet.connected ||
        !senderAddress ||
        !isEligible ||
        isClaiming ||
        isClaimed ||
        !hasFollowed
        ||
        !Join
      }
    >
      {isClaiming
        ? "Claiming..." : !wallet.connected ? "not connected"
        : !isEligible
          ? "Not Eligible" 
          : isClaimed
            ? "claimed"
            : !hasFollowed ? "follow first" : !Join ? "Join Community first" : "Claim Tokens"}
    </button>
  </div>
  <div className="flex justify-center items-center mt-4">
    {status && <p className="text-center text-sm md:text-base">{status}</p>}
  </div>
</div>

  );
};

export default ClaimButton;
